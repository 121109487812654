import * as React from 'react';
import { graphql } from 'gatsby';
import Master from 'layouts/Master';
import PostList from 'components/PostList';
import Page from 'models/Page';
import PageUtils from 'utils/PageUtils';

type TagGroup = {
  fieldValue: string;
  totalCount: number;
};

type Data = {
  articles: {
    edges: Page[];
  };
  tags: {
    group: TagGroup[];
  };
};

type Props = {
  data: Data;
};

type State = {
  search: string;
  selectedTags: string[];
  articles: Page[];
};

export default class BlogPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
      selectedTags: [],
      articles: props.data.articles.edges,
    };
  }

  private filterModels = () => {
    const { search, selectedTags } = this.state;
    const { data } = this.props;

    let articles = data.articles.edges.filter(p =>
      p.node.frontmatter.title.toLowerCase().includes(search.toLowerCase())
    );

    if (selectedTags.length > 0) {
      articles = articles.filter(
        article =>
          article.node.frontmatter.tags &&
          selectedTags.every(t => article.node.frontmatter.tags.includes(t))
      );
    }

    this.setState({ articles });
  };

  private handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      search: event.target.value,
    }, this.filterModels);
  };

  private handleTagClick = (tag: TagGroup) => () => {
    let selectedTags = [...this.state.selectedTags];
    if (selectedTags.includes(tag.fieldValue)) {
      selectedTags = selectedTags.filter(t => t !== tag.fieldValue);
    } else {
      selectedTags.push(tag.fieldValue);
    }

    this.setState({
      selectedTags,
    }, this.filterModels);
  };

  render() {
    const articles = this.state.articles;
    const tags = this.props.data.tags.group;

    return (
      <Master title={PageUtils.generateTitle('Blog')}>

        <div className="container">
          <div>
            <h1>Blog</h1>
            <p>Check out our blog posts about the best big boobs cam girls</p>

            <div className="models-page__categories-list">
              {tags.map(t => {
                const isActive = this.state.selectedTags.includes(t.fieldValue);

                return (
                  <span
                    key={t.fieldValue}
                    onClick={this.handleTagClick(t)}
                    className={isActive ? `models-page__categories-list__item models-page__categories-list__item--active` : 'models-page__categories-list__item'}
                  >
                    {`${t.fieldValue} ${t.totalCount}`}
                  </span>
                )
              })}
            </div>
          </div>

          <div className="models-page__search__container">
            <input
              type="text"
              name="search"
              value={this.state.search}
              placeholder="Search..."
              onChange={this.handleOnSearchChange}
              className="models-page__search__input"
            />
            <span className="models-page__search__count">{articles.length}</span>
          </div>

          <section>
            <PostList posts={articles} />
          </section>
        </div>
      </Master>
    );
  }

}

export const pageQuery = graphql`
  query ArticlesQuery {
    articles: allMarkdownRemark(filter: { frontmatter: {  template: { eq: "article" } } }, limit: 2000, sort: { fields: [fields___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 180)
          timeToRead
          frontmatter {
            title
            tags
            remoteThumbnail
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
            date
            template
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
